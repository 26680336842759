.pdfForm {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
  }



@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  
.divider2 {
    width: 100%;
    height: 3px;
    background-color: #ccc; /* Adjust the color to your preference */
    margin: 10px 0; /* Adjust the margin to control the spacing around the divider */
  }

  .welcome{
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .chooseus {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  