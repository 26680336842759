/* Navbar.css */

.navbar {
    border-bottom: 4mm ridge rgba(211, 220, 50, .6);
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .navbar-logo {
    font-size: 24px;
    font-weight: 700;
  }
  
  .navbar-nav {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    /* Add a margin-top to push the items above the border */
    margin-top: 20mm;
  }
  
  .nav-item {
    margin: 0 20px;
    position: relative;
  }
  
  .nav-item::after {
    content: '';
    position: absolute;
    top: 0%;
    right: -20px; /* Adjust this value to control the distance of the line from the text */
    height: 2em; /* Adjust this value to control the height of the line */
    width: 1px; /* Adjust this value to control the width of the line */
    background-color: #000; /* Color of the line */
  }

  .nav-item a {
    text-decoration: none;
    color: #000000;
  }
  
  .nav-item a:hover {
    font-weight: 900; /* Change this to your desired hover color */
    border-bottom: solid;
    border-color: #000;
  }

  img {
    width: 250px;
    height: 100px;
  }