@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');

.App {
  text-align: center;
  font-family: Comfortaa;
}
.App-body {
  background-color: #f4f4f4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}