/* Footer.css */

.footer {
    background-color: #ffffff; /* Background color for the footer */
    color: #000000; /* Text color for the footer content */
    padding: 20px 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  
  .footer-links {
    ul.footer-nav {
      list-style: none;
      padding: 0;
      display: flex;
      li {
        margin: 0 15px;
        a {
          text-decoration: none;
          color: #fff;
          transition: color 0.3s;
          &:hover {
            color: #ff5733; /* Change this to your desired hover color */
          }
        }
      }
    }
  }
  
  .footer-info {
    text-align: center;
    margin-top: 20px;
    p {
      margin: 0;
    }
  }
  