.pdfGenerator{
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
}


@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }



.cardContent {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 1000px;
    
    border:  100px solid;
}

.RichTextEditor {
    width: 900px;
}

.sendPDF {
    margin-top: 20px;
}


.likeandAdmire {
  padding-bottom: 40px; /* Adjust the value as needed */
}

.howtoBest {
  padding-bottom: 40px; /* Adjust the value as needed */
}

.disclaimerText {
  padding-bottom: 40px;
  color: #8b0000;
}

.disclaimerBackground {
  padding-top: 40px;
  background-color: #ffe5e5;
}

.viewPDF {
  padding-bottom: 40px;
}

.bestSupport {
  padding-bottom: 20px;
}

.fullName {
  padding-bottom: 100px;
}

.fullNameTitle {
  padding-top: 40px;
}

.imageUpload1 {
  padding-bottom: 40px;
}

.imageUpload2 {
  padding-bottom: 40px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #ccc; /* Adjust the color to your preference */
  margin: 10px 0; /* Adjust the margin to control the spacing around the divider */
}

.fullNameTextbox {
  padding-bottom: 60px;
}

.portrait {
  padding-bottom: 60px;
}